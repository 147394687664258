import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  getQueryParamNames,
  isOriginInUse,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import {
  colorFilter,
  conditionFilter,
  listingFields,
  materialFilter,
} from '../../config/configListing';
import { marketplaceData } from '../../marketplace-data';
import { languageType } from '../../util/languageType';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      keyword: '',
    };
    this.componentRef = React.createRef();
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.defaultOptions = this.defaultOptions.bind(this);
    this.productsIfBrand = this.productsIfBrand.bind(this);
    this.subCategoriesIfBrand = this.subCategoriesIfBrand.bind(this);
    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
    this.handleRemoveOneFilter = this.handleRemoveOneFilter.bind(this);
    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal(state) {
    const { history, routeConfiguration, config } = this.props;
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, showFilters: state };
    const data = showAll
      ? {
          searchTerm: 'all_products',
        }
      : {};
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {
          ...data,
        },
        searchParams
      )
    );
    // this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);
    // Reset state
    this.setState({ currentQueryParams: {} });

    const isMegaMenu = !showAll;
    const category = urlQueryParams?.pub_category;

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    const queryParamsOnlyCategory = isMegaMenu
      ? {
          pub_department: urlQueryParams.pub_department,
          pub_category: urlQueryParams.pub_category,
        }
      : { pub_department: urlQueryParams.pub_department };
    const data = showAll
      ? {
          searchTerm: 'all_products',
        }
      : {};
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {
          ...data,
        },
        queryParamsOnlyCategory
      )
    );
  }
  handleKeyword(e) {
    this.setState({ keyword: e.target.value });
  }
  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const filterData = marketplaceData;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const category = urlQueryParams?.pub_department || 'women';
    const isCategoryKids = category === 'kids';
    const isSubCategorySelected = urlQueryParams?.pub_category;
    const selectedProduct = urlQueryParams?.pub_subCategory;

    const isBrandSelected = urlQueryParams?.pub_designer;
    const mainFilter = filterData?.find(d => d.value === category);
    let genderFilter = isCategoryKids ? mainFilter?.gender : null;
    const isGenderFilterActive = urlQueryParams?.pub_gender;
    const allGenderSubCategories = genderFilter?.enumOptions?.map(d => d.subCategories);
    const defaultSubCategoryFilterOptions = isCategoryKids
      ? this.defaultOptions(allGenderSubCategories)
      : null;
    let subCategoryFilter = isGenderFilterActive
      ? genderFilter?.enumOptions?.find(d => d.value === urlQueryParams?.pub_gender)?.subCategories
      : mainFilter?.subCategories || defaultSubCategoryFilterOptions;

    const finalSubCategoryFilter = isBrandSelected
      ? this.subCategoriesIfBrand(subCategoryFilter)
      : subCategoryFilter;
    const allProducts = subCategoryFilter?.enumOptions?.map(d => d.products);

    const defaultProductFilterOptions = this.defaultOptions(allProducts);
    let productFilter = isBrandSelected
      ? this.productsIfBrand(defaultProductFilterOptions, subCategoryFilter)
      : urlQueryParams?.pub_category
      ? subCategoryFilter?.enumOptions?.find(d => d.value === urlQueryParams?.pub_category)
          ?.products
      : defaultProductFilterOptions;

    const isSubcategoryChanged = selectedProduct
      ? productFilter?.enumOptions || []?.find(d => d.value === selectedProduct)
        ? selectedProduct
        : null
      : null;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        // const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        let mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
          pub_department: urlQueryParams.pub_department,
          pub_category: urlQueryParams.pub_category,
          pub_subCategory: urlQueryParams.pub_subCategory,
          pub_designer: urlQueryParams.pub_designer,
        };
        if (
          updatedURLParams.hasOwnProperty('pub_department') &&
          prevState.currentQueryParams['pub_department'] != updatedURLParams['pub_department']
        ) {
          mergedQueryParams = {};
        }
        if (
          updatedURLParams.hasOwnProperty('pub_category') &&
          prevState.currentQueryParams['pub_category'] != updatedURLParams['pub_category']
        ) {
          mergedQueryParams = {
            ...mergedQueryParams,
            pub_subCategory: null,
            pub_size: null,
            pub_colour: null,
            pub_condition: null,
            price: null,
            pub_material: null,
          };
        }
        if (
          updatedURLParams.hasOwnProperty('pub_designer') &&
          prevState.currentQueryParams['pub_designer'] != updatedURLParams['pub_designer']
        ) {
          this.setState({ keyword: '' });
          mergedQueryParams = {
            ...mergedQueryParams,
            pub_category: isSubCategorySelected
              ? finalSubCategoryFilter?.enumOptions ||
                []?.find(d => d.value === isSubCategorySelected)
                ? isSubCategorySelected
                : null
              : null,
            // pub_subCategory: selectedProduct
            //   ? productFilter?.enumOptions || []?.find(d => d.value === selectedProduct)
            //     ? selectedProduct
            //     : null
            //   : null,
            pub_subCategory: isSubcategoryChanged,
            // pub_category: null,
            pub_size: null,
            pub_colour: null,
            price: null,
            pub_condition: null,
            pub_material: null,
          };
        }
        if (
          updatedURLParams.hasOwnProperty('pub_subCategory') &&
          prevState.currentQueryParams['pub_subCategory'] != updatedURLParams['pub_subCategory']
        ) {
          mergedQueryParams = {
            ...mergedQueryParams,
            pub_size: null,
            pub_colour: null,
            pub_condition: null,

            pub_material: null,
          };
        }
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          const data = showAll
            ? {
                searchTerm: 'all_products',
              }
            : {};
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration,
              {
                ...data,
              },
              search
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }
  componentDidMount() {
    const { currentUser, history, routeConfiguration } = this.props;

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const department = urlQueryParams?.pub_department;
    if (!department) {
      const queryParams = { ...urlQueryParams, pub_department: 'women' };
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration,
          { searchTerm: 'all_products' },
          queryParams
        )
      );
    }
  }
  // componentDidUpdate(prevProps) {
  //   // Check if the `listings` prop has changed
  //   if (prevProps.listings !== this.props.listings) {
  //     if (this.componentRef.current) {
  //       this.componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     }
  //   }
  // }
  componentDidUpdate(prevProps) {
    const { history } = this.props;
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const isCategoryChanged = urlQueryParams?.pub_category !== prevProps.searchParams?.pub_category;
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({ keyword: '' });
    }
    if (this.componentRef.current && isCategoryChanged && !showAll) {
      const container = this.componentRef.current;
      container.scrollTop = 0; // Scroll to the top
    }
  }
  productsIfBrand(options, subCategoryFilter) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const brand = urlQueryParams?.pub_designer;
    const subCategory = urlQueryParams?.pub_category;

    function filterProductsByBrand(products, brandName) {
      return products.filter(product =>
        product.brands?.enumOptions?.some(brand => brand?.value === brandName)
      );
    }
    if (subCategory) {
      const newOptions = subCategoryFilter?.enumOptions?.find(d => d.value === subCategory)
        ?.products;
      newOptions.enumOptions = filterProductsByBrand(newOptions?.enumOptions, brand);
      return newOptions;
    }

    options.enumOptions = filterProductsByBrand(options?.enumOptions, brand);

    return options;
  }
  subCategoriesIfBrand(options) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const brand = urlQueryParams?.pub_designer;

    function filterProductsByBrand(products, brandName) {
      return products.filter(product =>
        product.brands?.enumOptions.some(brand => brand.value === brandName)
      );
    }
    function filterSubcategoryByBrand(subCategory, brandName) {
      return subCategory.map(d => {
        const products = d.products;
        const filteredProducts = filterProductsByBrand(products?.enumOptions, brandName);
        if (filteredProducts.length > 0) {
          return {
            ...d,
            products: {
              ...products,
              enumOptions: filteredProducts,
            },
          };
        } else {
          return null;
        }
      });
    }
    const newOptions = filterSubcategoryByBrand(options.enumOptions, brand)?.filter(
      d => d !== null
    );
    const data = {
      ...options,
      enumOptions: newOptions,
    };

    return data;
  }
  defaultOptions(options) {
    // console.log('options', options);
    if (!options) return [];
    const mergedEnumOptions = options?.reduce((acc, curr) => {
      curr.enumOptions.forEach(d => {
        if (!acc.find(e => e.value === d.value && e.show)) {
          acc.push(d);
        }
      });
      return acc;
    }, []);
    const mergedObject = {
      filterConfig: options[0]?.filterConfig,
      key: options[0]?.key,
      schemaType: options[0]?.schemaType,
      scope: options[0]?.scope,
      enumOptions: mergedEnumOptions,
    };

    return mergedObject;
  }
  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    const data = showAll
      ? {
          searchTerm: 'all_products',
        }
      : {};
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {
          ...data,
        },
        queryParams
      )
    );
  }
  handleRemoveOneFilter = (key, value) => {
    const { history, routeConfiguration } = this.props;
    const location = history.location;
    const pathname = location?.pathname;
    const showAll = pathname.includes('all_products');
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const values = urlQueryParams[key]
      .split(',')
      .filter(v => v !== value)
      .join(',');
    let queryParams = values ? { ...urlQueryParams, [key]: values } : omit(urlQueryParams, key);
    if (key === 'pub_category') {
      queryParams = {
        ...queryParams,
        pub_subCategory: null,
        pub_size: null,
        pub_colour: null,
        pub_condition: null,
        pub_material: null,
      };
    }
    if (key === 'pub_designer') {
      queryParams = {
        ...queryParams,
        pub_subCategory: null,
        pub_size: null,
        pub_colour: null,
        pub_condition: null,
        pub_material: null,
      };
    }
    if (key === 'pub_subCategory') {
      queryParams = {
        ...queryParams,
        pub_size: null,
        pub_colour: null,
        pub_condition: null,
        pub_material: null,
      };
    }
    if (key === 'pub_department') {
      queryParams = {
        ...queryParams,
        pub_category: null,
        pub_subCategory: null,
        pub_size: null,
        pub_colour: null,
        pub_condition: null,
        pub_material: null,
      };
    }

    this.setState({ currentQueryParams: queryParams });
    const data = showAll
      ? {
          searchTerm: 'all_products',
        }
      : {};
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {
          ...data,
        },
        queryParams
      )
    );
  };
  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      routeConfiguration,
      config,
    } = this.props;
    const isLanguageEn = languageType() === 'en-US';
    const filterData = marketplaceData;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );
    const showFilterInMobile = searchParams?.showFilters;
    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const isSelectedFilterLengthGreaterThanOne = Object.keys(validQueryParams).length > 1;
    // aarsh
    const category = validQueryParams?.pub_department || 'women';
    const isCategoryKids = category === 'kids';
    const isSubCategorySelected = validQueryParams?.pub_category;
    const isBrandSelected = validQueryParams?.pub_designer;
    const mainFilter = filterData?.find(d => d.value === category);
    let genderFilter = isCategoryKids ? mainFilter?.gender : null;
    const isGenderFilterActive = validQueryParams?.pub_gender;
    const allGenderSubCategories = genderFilter?.enumOptions?.map(d => d.subCategories);
    const defaultSubCategoryFilterOptions = isCategoryKids
      ? this.defaultOptions(allGenderSubCategories)
      : null;
    let subCategoryFilter = isGenderFilterActive
      ? genderFilter?.enumOptions?.find(d => d.value === validQueryParams?.pub_gender)
          ?.subCategories
      : mainFilter?.subCategories || defaultSubCategoryFilterOptions;

    const finalSubCategoryFilter = isBrandSelected
      ? this.subCategoriesIfBrand(subCategoryFilter)
      : subCategoryFilter;

    // aarsh

    const allProducts = subCategoryFilter?.enumOptions?.map(d => d.products);

    const defaultProductFilterOptions = this.defaultOptions(allProducts);

    let productFilter = isBrandSelected
      ? this.productsIfBrand(defaultProductFilterOptions, subCategoryFilter)
      : validQueryParams?.pub_category
      ? subCategoryFilter?.enumOptions?.find(d => d.value === validQueryParams?.pub_category)
          ?.products
      : defaultProductFilterOptions;
    const allSizes = productFilter?.enumOptions?.map(d => d.sizes)?.filter(d => d);
    const defaultSizeFilterOptions = this.defaultOptions(allSizes);
    let sizeFilter = validQueryParams?.pub_subCategory
      ? productFilter?.enumOptions?.find(d => d.value === validQueryParams?.pub_subCategory)
          ?.sizes || null
      : defaultSizeFilterOptions;

    const allBrands = this.defaultOptions(allProducts)?.enumOptions?.map(d => d.brands);
    const defaultBrandFilterOptions = this.defaultOptions(allBrands);
    let brandFilter = validQueryParams?.pub_subCategory
      ? productFilter?.enumOptions?.find(d => d.value === validQueryParams?.pub_subCategory)?.brands
      : defaultBrandFilterOptions;

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const sortedProductFilter = {
      ...productFilter,
      enumOptions: productFilter?.enumOptions?.sort((a, b) => {
        const locale = isLanguageEn ? 'en' : 'el'; // Use 'en' for English or 'el' for Greek
        return a?.label?.localeCompare(b?.label, locale, { sensitivity: 'base' });
      }),
    };
    let availableFilters;
    if (isCategoryKids) {
      availableFilters = [
        ...customPrimaryFilters,
        ...customSecondaryFilters,
        ...listingFields,
        finalSubCategoryFilter,
        sortedProductFilter,
        // productFilter,
        brandFilter,
        genderFilter,
        // subCategoryFilter,

        // sizeFilter,

        colorFilter,
        conditionFilter,
        ...defaultFilters,
        materialFilter,
      ];
    } else {
      availableFilters = [
        ...customPrimaryFilters,

        ...customSecondaryFilters,
        ...listingFields,
        finalSubCategoryFilter,
        // productFilter,
        sortedProductFilter,
        brandFilter,
        // subCategoryFilter,

        // sizeFilter,

        colorFilter,
        conditionFilter,
        ...defaultFilters,
      ];
    }
    if (isSubCategorySelected) {
      availableFilters = [...availableFilters];
    } else {
      availableFilters = [
        ...customPrimaryFilters,

        ...customSecondaryFilters,
        ...listingFields,
        finalSubCategoryFilter,
        // productFilter,
        brandFilter,
        // subCategoryFilter,

        // sizeFilter,

        colorFilter,
        conditionFilter,
        ...defaultFilters,
      ];
    }
    if (
      (validQueryParams?.pub_category && validQueryParams?.pub_category !== 'accessories') ||
      validQueryParams?.pub_subCategory
    ) {
      if (sizeFilter) {
        const availableFiltersWithoutPrice = availableFilters.filter(d => d.key !== 'price');
        // console.log('availableFiltersWithoutPrice', availableFiltersWithoutPrice);
        availableFilters = [
          ...availableFiltersWithoutPrice,
          sizeFilter,
          ...defaultFilters,
          materialFilter,
        ];
      } else {
        availableFilters = [...availableFilters, materialFilter];
      }
    }

    if (!mainFilter) {
      availableFilters = [];
    }

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );

    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          rootClassName={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          showFilterInMobile={showFilterInMobile}
        />
        <div className={css.root}>
          <div className={css.layoutWrapperContainer}>
            <aside className={css.layoutWrapperFilterColumn} data-testid="filterColumnAside">
              <div className={css.filterColumnContent} ref={this.componentRef}>
                {availableFilters?.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config?.scope || 'built-in'}.${config.key}`}
                      idPrefix="SearchFiltersMobile"
                      className={css.filter}
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={urlQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      keyword={this.state.keyword}
                      handleKeyword={this.handleKeyword}
                      intl={intl}
                      // history={history}
                      liveEdit
                      showAsPopup={false}
                      isDesktop
                    />
                  );
                })}
                {isSelectedFilterLengthGreaterThanOne ? (
                  <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                    <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                  </button>
                ) : null}
              </div>
            </aside>

            <div className={css.layoutWrapperMain} role="main">
              <div className={css.searchResultContainer}>
                <SearchFiltersMobile
                  className={css.searchFiltersMobileList}
                  urlQueryParams={validQueryParams}
                  sortByComponent={sortBy('mobile')}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  showAsModalMaxWidth={MODAL_BREAKPOINT}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onOpenModal={this.onOpenMobileModal}
                  onCloseModal={this.onCloseMobileModal}
                  resetAll={this.resetAll}
                  selectedFiltersCount={selectedFiltersCountForMobile}
                  isMapVariant={false}
                  noResultsInfo={noResultsInfo}
                  keyword={this.state.keyword}
                  handleKeyword={this.handleKeyword}
                  validQueryParams={validQueryParams}
                  showFilterInMobile={showFilterInMobile}
                  handleRemoveOneFilter={this.handleRemoveOneFilter}
                  availableFilters={availableFilters}
                >
                  {availableFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                        idPrefix="SearchFiltersMobile"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        keyword={this.state.keyword}
                        handleKeyword={this.handleKeyword}
                        intl={intl}
                        liveEdit
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersMobile>
                <MainPanelHeader
                  className={css.mainPanel}
                  sortByComponent={sortBy('desktop')}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                  selectedFilters={selectedFilters}
                  validQueryParams={validQueryParams}
                  handleRemoveOneFilter={this.handleRemoveOneFilter}
                  availableFilters={availableFilters}
                  resetAll={this.resetAll}
                />
                <div
                  className={classNames(css.listingsForGridVariant, {
                    [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                  })}
                >
                  {searchListingsError ? (
                    <H3 className={css.error}>
                      <FormattedMessage id="SearchPage.searchError" />
                    </H3>
                  ) : null}
                  {!isValidDatesFilter ? (
                    <H5>
                      <FormattedMessage id="SearchPage.invalidDatesFilter" />
                    </H5>
                  ) : null}
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    isMapVariant={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterContainer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
};

SearchPageComponent.propTypes = {
  listings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
